import { FeaturedPost, PostList } from 'components/Blog';
import Layout from 'components/Layout';
import { SliceZone } from 'components/Shared';
import { graphql, navigate } from 'gatsby';
import React from 'react';

const Blog = ({ data, location, pageContext: { numPages, currentPage } }) => {
  const {
    data: { featured_post, meta_title, meta_description, meta_image, body },
    lang: pageLang,
    alternate_languages: altLangs,
  } = data.blog.edges[0].node;

  const { title, subtitle, button_label } = data.leadSignup.edges[0].node.data;
  const isFirstPage = currentPage === 1;
  const onPageChange = ({ selected }) =>
    navigate(
      `${pageLang === 'de-de' ? '/de-de' : ''}/blog${
        selected === 0 ? '' : `/${selected}`
      }`
    );

  return (
    <Layout
      pageLang={pageLang}
      altLangs={altLangs}
      location={location}
      seo={{
        title: meta_title || 'Blog',
        description: meta_description,
        image: meta_image?.url,
      }}
      whiteHeader={true}
    >
      {isFirstPage && featured_post?.document && (
        <FeaturedPost post={featured_post?.document} pageLang={pageLang} />
      )}
      <PostList
        posts={data.posts.edges}
        pageCount={numPages}
        currentPage={currentPage - 1}
        onPageChange={onPageChange}
      />
      <SliceZone allSlices={body} />
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query BlogQuery($skip: Int!, $limit: Int!, $locale: String!) {
    blog: allPrismicBlog(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          alternate_languages {
            lang
            uid
          }
          type
          data {
            meta_description
            meta_title
            meta_image {
              url
            }
            featured_post {
              document {
                ... on PrismicBlogPost {
                  id
                  uid
                  url
                  first_publication_date
                  data {
                    title {
                      text
                    }
                    subtitle {
                      text
                    }
                    featured_image {
                      url
                    }
                  }
                }
              }
            }
            body {
              ... on PrismicBlogBodyDarkDivider {
                id
                primary {
                  headline_post_highlight {
                    text
                  }
                  headline_highlight {
                    text
                  }
                  headline_pre_highlight {
                    text
                  }
                  text {
                    text
                    html
                  }
                  button_text {
                    text
                  }
                  button_text_demo {
                    text
                  }
                  button_text_secondary {
                    text
                  }
                  navigation_link {
                    url
                  }
                  navigation_link_secondary {
                    url
                  }
                  image_avatar {
                    url
                    alt
                  }
                  avatar_name {
                    text
                  }
                  avatar_title {
                    text
                  }
                }
                slice_type
              }
            }
          }
        }
      }
    }
    posts: allPrismicBlogPost(
      sort: { fields: first_publication_date, order: DESC }
      skip: $skip
      limit: $limit
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          lang
          url
          data {
            title {
              text
            }
            subtitle {
              text
            }
            featured_image {
              url
            }
          }
          uid
          first_publication_date
        }
      }
    }
    leadSignup: allPrismicLeadSignup(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            button_label {
              text
            }
            subtitle {
              text
            }
          }
        }
      }
    }
  }
`;
